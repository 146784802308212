<template functional>
  <span
    :class="`badge badge--${props.status} ${data.staticClass}`"
    :style="{ background: props.background, color: props.color }"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: 'SasBadge',

  props: {
    color: {
      type: String,
      default: '#78809A'
    },

    background: {
      type: String,
      default: '#9e9fb1'
    },

    status: {
      type: String,
      default: 'default'
    }
  }
}
</script>

<style lang="sass">

.badge
  display: inline-block
  text-align: center
  font-size: 13px
  padding: 4px 8px
  border-radius: 100px
  box-sizing: border-box

  &--success
    color: $color-success !important
    background: transparentize($color-success, .75) !important

  &--progress
    color: $color-secondary !important
    background: transparentize($color-secondary, .75) !important

  &--fail
    color: red !important
    background: transparentize(red, .75) !important

</style>
