import http from './http'

const getVideo = idVideo => {
    const resource = `/videos/${idVideo}`
    return http.get(resource)
}

const getChannelsList = async () => (
  await http.get('/channels')
)

const getCategoriesByChannel = async channelId => {
  const resource = `/channels/${channelId}/categories`
  return http.get(resource)
}

const getCategoryByPath = async path => {
  return http.get(path)
}

const getVideosByChannel = async channel => {
  const resource = `/channels/${channel.channelId}/videos?properties=${channel.query}`
  return http.get(resource)
}

export default {
    getVideo,
    getChannelsList,
    getCategoryByPath,
    getCategoriesByChannel,
    getVideosByChannel
}
