import vue from 'vue'

import TVSpinner from '@/components/global/TVSpinner'
import TVButton from '@/components/global/TVButton'
import SBadge from '@/components/global/SBadge'
import SkeletonBone from '@/components/global/SkeletonBone'
import TVBox from '@/components/global/TVBox'
import TVWrapper from '@/components/global/TVWrapper'
import TVInput from '@/components/global/TVInput'

import Icon from 'vue-feather'
import Select from 'vue-select'

vue.component('TVSpinner', TVSpinner)
vue.component('TVButton', TVButton)
vue.component('SBadge', SBadge)
vue.component('TVWrapper', TVWrapper)
vue.component('SkeletonBone', SkeletonBone)
vue.component('Icon', Icon)
vue.component('TVBox', TVBox)
vue.component('Select', Select)
vue.component('TVInput', TVInput)

