<template>
  <main class="app-main">
    <SMenu
      class="app-main__menu"
    />
    <div class="app-main__content">
      <div
        v-if="isLoading"
        class="app-main__loading"
      >
        <TVSpinner />
      </div>
      <slot />
    </div>
    <WelcomeModal
      v-if="enableWelcomeModal"
      @close="hideWelcomeModal"
      @start="startTour"
    />
    <TourCompletedModal
      v-if="isTourCompletedEnable"
      @close="hideTourFinishedModal"
    />
  </main>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { SMenu } from '@sas-te/main-menu-vue'

const WelcomeModal = () => import('@/components/WelcomeModal')
const TourCompletedModal = () => import('@/components/TourCompletedModal')

export default {
  name: 'Main',
  components: {
    WelcomeModal,
    TourCompletedModal,
    SMenu,
  },
  data() {
    return {
      onboardingCurrentUser: {},
      enableWelcomeModal: false,
      hasUpdate: true,
    }
  },
  computed: {
    ...mapState('profile', ['personalData']),
    ...mapGetters([
      'isLoading',
      'onboarding',
      'channelsList',
    ]),
    nodeEnv: () => process.env.NODE_ENV,
    isOnboardingEnabled() {
      this.setUser()

      if (!this.onboardingCurrentUser && this.personalData.id) {
        this.enableOnboarding()
      }

      if (this.channelsList.hasClassroomChannel && this.onboardingCurrentUser) {
        return JSON.parse(process.env.VUE_APP_ONBOARDING_ENABLED)
          && this.onboardingCurrentUser.welcomeActive
      }

      return false
    },
    isTourCompletedEnable() {
      if (this.onboarding.users[this.personalData.id]) {
        return this.onboarding.users[this.personalData.id].tourCompleted
      }

      return false
    }
  },
  watch: {
    personalData(personalData) {
      if (personalData) {
        this.showWelcomeModal()
      }
    },
    channelsList(channelsList) {
      if (channelsList.data.length) {
        this.showWelcomeModal()
      }
    },
  },
  mounted() {
    this.setUser()

    if (this.onboardingCurrentUser && this.onboardingCurrentUser.welcomeActive) {
      this.enableOnboarding()
    }
  },
  updated() {
    if (this.hasUpdate) {
      this.setUser()

      if (this.personalData.id
        && this.onboardingCurrentUser
        && this.onboardingCurrentUser.welcomeActive
      ) {
        this.enableOnboarding()
        this.hasUpdate = false
      }
    }
  },
  methods: {
    ...mapActions([
     'setOnboarding',
     'getUser',
    ]),
    showWelcomeModal() {
      this.enableWelcomeModal = this.isOnboardingEnabled && !this.isLoading
    },
    hideWelcomeModal() {
      this.enableWelcomeModal = false
      this.setOnboarding({
        ...this.onboarding,
        users: {
          ...this.onboarding.users,
          [this.personalData.id]: {
            ...this.onboardingCurrentUser,
            id: this.personalData.id,
            welcomeActive: false,
            tourActive: false,
          },
        },
      })
    },
    startTour() {
      this.$router.push({ name: 'home' })
      this.enableWelcomeModal = false
      this.setOnboarding({
        ...this.onboarding,
        users: {
          ...this.onboarding.users,
          [this.personalData.id]: {
            ...this.onboardingCurrentUser,
            id: this.personalData.id,
            welcomeActive: false,
            tourActive: true,
          },
        },
      })
    },
    hideTourFinishedModal() {
      this.setOnboarding({
        ...this.onboarding,
        users: {
          ...this.onboarding.users,
          [this.personalData.id]: {
            ...this.onboardingCurrentUser,
            id: this.personalData.id,
            tourCompleted: false,
          },
        },
      })
      this.$router.push({ name: 'home' })
    },
    setUser() {
      this.onboardingCurrentUser = this.onboarding.users[this.personalData.id]
    },
    enableOnboarding() {
      this.setOnboarding({
        ...this.onboarding,
        users: {
          ...this.onboarding.users,
          [this.personalData.id]: {
            ...this.onboarding.users[this.personalData.id],
            id: this.personalData.id,
            welcomeActive: true,
            tourActive: false,
          },
        },
      })
    }
  },
}
</script>

<style lang="sass">
.app-main
  &__menu
    margin-bottom: 70px

    .content-menu
      z-index: 10

  &__loading
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: calc(100vh - 56px)
</style>
