import * as types from '../mutation-types'

export default {
    [types.SET_VIDEO](state, video) {
        state.video = video
    },

    [types.SET_VIDEOS](state, videos) {
        state.videos = videos
    },

    [types.ADD_SELECTED_CATEGORY](state, category) {
        state.selectedCategories.push(category)
    },

    [types.REMOVE_SELECTED_CATEGORY](state) {
        state.selectedCategories.pop()
    },

    [types.CLEAR_SELECTED_CATEGORIES](state) {
        state.selectedCategories = []
    },

    [types.SET_CHANNELS_LIST](state, channelsList) {
        state.channelsList = {
            ...state.channelsList,
            ...channelsList,
        }
    },

    [types.SET_CATEGORIES](state, categories) {
        state.categories = categories
    },

    [types.SET_LOADING](state, loading) {
        state.loading = loading
    }
}
