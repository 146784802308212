import Vue from 'vue'
import Router from 'vue-router'

import PageError from '@/views/PageError'

const Video = () => import('@/views/Video')
const Home = () => import('@/views/Home')
const Categories = () => import('@/views/Categories')
const Videos = () => import('@/views/Videos')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/channels/:channel/videos/:video',
      name: 'video',
      component: Video,
      meta: {
        isCustomColor: true
      }
    },
    {
      path: '/channels/:channel/categories',
      name: 'categories',
      component: Categories,
      meta: {
        isCustomColor: true
      }
    },
    {
      path: '/channels/:channel/videos',
      name: 'videos',
      component: Videos,
      meta: {
        isCustomColor: true
      }
    },
    {
      path: '*',
      name: 'NotFound',
      component: PageError,
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

const trackView = (to) => {
  const inBrowser = typeof window !== 'undefined'

  if (inBrowser && window.dataLayer) {
    const name = to.meta.gtm || to.name

    window.dataLayer.push({
      event: 'Pageview',
      pagePath: window.location.href,
      pageTitle: name,
    })
  }
}

router.afterEach(trackView)

export default router
