<template>
  <div id="app">
    <SMenu class="app__menu" />
    <Main>
      <router-view />
    </Main>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { SMenu } from '@sas-te/main-menu-vue'

import Main from '@/components/Main'

let haveAlreadySendDataLayer = false

export default {
  name: 'App',
  components: {
    Main,
    SMenu,
  },
  computed: mapState('profile', ['personalData']),
  watch: {
    personalData() {
      if (
        !haveAlreadySendDataLayer
        && this.personalData
        && JSON.parse(process.env.VUE_APP_GTM_ENABLED)
      ) {
        this.pushUserToDataLayer()
      }
    }
  },
  methods: {
    pushUserToDataLayer() {
      const {
        id: accountId,
        schoolId,
        profileId,
        schoolProspection
      } = this.personalData

      window.dataLayer.push({
        accountId,
        schoolId,
        profileId,
        prospection: schoolProspection
      })
      haveAlreadySendDataLayer = true
    },
  },
}
</script>

<style lang="sass">
@import "~@sas-te/alfabeto-vue"

body
  background: $color-ice

.app-main
  margin-top: 71px
</style>
