import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const onboarding =  {
  users: {},
}

const state = {
  onboarding: JSON.parse(localStorage.getItem('@onboarding/SASTV')) || onboarding,
}

export default {
  state,
  getters,
  mutations,
  actions
}
