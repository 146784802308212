<template>
  <div
    :style="{ width }"
    class="sas-input"
  >
    <!-- label -->
    <label
      v-if="label"
      :class="`--${status}`"
      :for="name"
      class="sas-input__label"
    >
      {{ label }}
    </label>

    <!-- icon -->
    <icon
      v-if="iconLeft || iconRight"
      :class="[
        `--${size}`,
        { '--left' : iconLeft },
        { '--right' : iconRight }
      ]"
      :type="iconLeft || iconRight"
      :style="iconLeft ? { left: 0 } : { right : 0 }"
      :stroke="iconColor"
      class="sas-input__icon"
    />

    <!-- icon -->
    <input
      ref="input"
      :autocomplete="autocomplete"
      :class="[
        `--${size}`,
        status ? `--${status}` : '',
        { '--disabled': disabled },
        { '--icon-left': iconLeft },
        { '--icon-right': iconRight }
      ]"
      :name="name"
      :required="required"
      :type="type"
      :disabled="disabled"
      :value="value"
      :placeholder="placeholder"
      :maxlength="maxlength"
      class="sas-input__field"
      @keyup.enter="$emit('enter')"
      @blur="$emit('blur')"
      @input="$emit('input', $event.target.value)"
    >
  </div>
</template>

<script>
// import tokens from '@/assets/tokens/tokens'
/**
  * SAS Educação Official Input
  *
  * @displayName Input
  * @example ../../docs/components/examples/TVInput.md
  */
export default {
  name: 'TVInput',
  status: 'ready',
  version: '1.0.0',

  props: {
    /**
      * Activate loading state of the input
      */
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
      * Name of the input
      */
    name: {
      type: String,
      default: null,
      required: false
    },
    /**
      * @model
      */
    value: {
      type: [String, Date],
      default: null,
      required: false
    },
    /**
      * Placeholder text
      */
    placeholder: {
      type: String,
      default: null,
      required: true
    },
    /**
      * Validate if the input is required
      */
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
      * Max length of characters
      */
    maxlength: {
      type: Number,
      default: null,
      required: false
    },
    /**
      * Set the type of the input
      * `text, number, date...`
      */
    type: {
      type: String,
      default: 'text',
      required: false
    },
    /**
      * Label of input
      */
    label: {
      type: String,
      default: null,
      required: false
    },
    /**
      * Size of the icon
      * `small, medium, large`
		  */
    size: {
      type: String,
      default: 'medium',
      required: false,
      validator: value => {
        return value.match(/(small|medium|large)/)
      }
    },
    /**
      * Name of the Right Icon: adds a icon on the right of the input
      * Uses `feather-icons`
		  */
    iconRight: {
      type: String,
      default: null,
      required: false
    },
    /**
      * Name of the Left Icon: adds a icon on the left of the input
      * Uses `feather-icons`
		  */
    iconLeft: {
      type: String,
      default: null,
      required: false
    },
    /**
      * Sets the icon color
		  */
    iconColor: {
      type: String,
      default: 'rgb(145, 146, 149)',
      required: false
    },
    /**
      * Status of the input
      * `success, error, warning`
		  */
    status: {
      type: String,
      default: null,
      required: false,
      validator: value => {
        return value.match(/(success|error|warning)/)
      }
    },
    /**
      * Set the Input to disabled
		  */
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
      * Set autocomplete name
		  */
    autocomplete: {
      type: String,
      default: null,
      required: false
    },
    /**
      * Width of the input
		  */
    width: {
      type: String,
      default: null,
      required: false
    }
  }
}
</script>

<style lang="sass">

// --------------------------------------------------------------
// Variables
// --------------------------------------------------------------

// Input Heights
$input-size-l: 44px
$input-size-m: 36px
$input-size-s: 28px

// Input Padding
$input-padding-l: 16px
$input-padding-m: 12px
$input-padding-s: 16px

// Icon Sizes
$input-icon-size-l: 24px
$input-icon-size-m: 20px
$input-icon-size-s: 16px

// Icon Paddings
$input-icon-padding-l: $input-padding-l - 16px
$input-icon-padding-m: $input-padding-m - 16px
$input-icon-padding-s: $input-padding-s

// Background
$input-background: tint-color(rgb(240, 240, 240), 25%)

// Input Borders and Outlines
$input-inset-shadow: inset 0px 1px 2px rgba(34, 36, 42, 0.15)
$input-border-color: rgba(34, 36, 42, 0.25)
$border-radius-m: 8px
$border-radius-s: 6px

$color-ink: rgb(34, 36, 42)
$color-ink-light: rgb(89, 91, 95)
$color-ink-lightest: rgb(199, 200, 201)
$color-ink-lighter: rgb(145, 146, 149)
$color-danger-dark: rgb(184, 9, 10)
$color-danger-light: rgb(240, 64, 64)
$color-success-dark: rgb(27, 136, 61)
$color-success-light: rgb(83, 191, 115)
$color-warning: rgb(255, 200, 6)
$color-warning-dark: rgb(199, 166, 47)

$font-size-m: 16px
$font-size-s: 14px
$font-size-xs: 12px

$speed-fast: 200ms

// --------------------------------------------------------------
// Base
// --------------------------------------------------------------
.sas-input
  position: relative
  display: inline-block

  &__label
    display: block
    color: $color-ink-light
    margin-bottom: 16px
    line-height: 1

  &__field
    appearance: none
    position: relative
    display: inline-block
    width: 100%
    color: $color-ink
    border: 1px solid $input-border-color
    background-color: $input-background
    box-shadow: $input-inset-shadow
    +transition($speed-fast)
    +space-inset(0 12px)

    &::placeholder
      color: $color-ink-lighter

    &:focus
      outline: none
      +input-focus-outline

    &.--error
      +input-focus-outline($color-danger-light)
      &:focus
        +input-focus-outline($color-danger-dark)

    &.--success
      +input-focus-outline($color-success-light)
      &:focus
        +input-focus-outline($color-success-dark)

    &.--warning
      +input-focus-outline($color-warning)
      &:focus
        +input-focus-outline($color-warning-dark)

    // --------------------------------------------------------------
    // Sizes
    // --------------------------------------------------------------
    &.--large
      border-radius: $border-radius-m
      height: $input-size-l
      +space-inset(0 $input-padding-l)
      &, &::placeholder
        font-size: $font-size-m

    &.--medium
      border-radius: $border-radius-s
      height: $input-size-m
      +space-inset(0 $input-padding-m)
      &, &::placeholder
        font-size: $font-size-s

    &.--small
      border-radius: $border-radius-s
      height: $input-size-s
      +space-inset(0 $input-padding-s)
      &, &::placeholder
        font-size: $font-size-xs


    // --------------------------------------------------------------
    // Fix input padding for inputs
    // Take icon size + its padding and + 8px
    // --------------------------------------------------------------
    $computed-icon-size-l: $input-icon-size-l + $input-icon-padding-l + 16px
    $computed-icon-size-m: $input-icon-size-m + $input-icon-padding-m + 16px
    $computed-icon-size-s: $input-icon-size-s + $input-icon-padding-s + 16px

    &.--icon-left
      &.--large
        padding-left: $computed-icon-size-l
      &.--medium
        padding-left: $computed-icon-size-m
      &.--small
        padding-left: $computed-icon-size-s

    &.--icon-right
      &.--large
        padding-right: $computed-icon-size-l
      &.--medium
        padding-right: $computed-icon-size-m
      &.--small
        padding-right: $computed-icon-size-s


  // --------------------------------------------------------------
  // Icon
  // --------------------------------------------------------------
  &__icon
    bottom: 0
    position: absolute
    z-index: 1
    pointer-events: none
    svg
      box-sizing: content-box

    // Sizes
    &.--large svg
      width: $input-icon-size-l
      height: $input-size-l
      +space-inset(0 $input-icon-padding-l)

    &.--medium svg
      width: $input-icon-size-m
      height: $input-size-m
      +space-inset(0 $input-icon-padding-m)
      stroke-width: 2.3 // fix stroke scaling

    &.--small svg
      width: $input-icon-size-s
      height: $input-size-s
      +space-inset(0 $input-icon-padding-s)
      stroke-width: 2.5 // fix stroke scaling

</style>
