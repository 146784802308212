import Vue from 'vue'
import Vuex from 'vuex'

import loader from '@/store/loader'
import video from '@/store/video'
import onboarding from '@/store/onboarding'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    loader,
    video,
    onboarding,
  },
  strict: process.env.NODE_ENV !== 'production'
})
