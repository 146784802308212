var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sas-input",style:({ width: _vm.width })},[(_vm.label)?_c('label',{staticClass:"sas-input__label",class:("--" + _vm.status),attrs:{"for":_vm.name}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.iconLeft || _vm.iconRight)?_c('icon',{staticClass:"sas-input__icon",class:[
      ("--" + _vm.size),
      { '--left' : _vm.iconLeft },
      { '--right' : _vm.iconRight }
    ],style:(_vm.iconLeft ? { left: 0 } : { right : 0 }),attrs:{"type":_vm.iconLeft || _vm.iconRight,"stroke":_vm.iconColor}}):_vm._e(),_c('input',{ref:"input",staticClass:"sas-input__field",class:[
      ("--" + _vm.size),
      _vm.status ? ("--" + _vm.status) : '',
      { '--disabled': _vm.disabled },
      { '--icon-left': _vm.iconLeft },
      { '--icon-right': _vm.iconRight }
    ],attrs:{"autocomplete":_vm.autocomplete,"name":_vm.name,"required":_vm.required,"type":_vm.type,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"maxlength":_vm.maxlength},domProps:{"value":_vm.value},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')},"blur":function($event){return _vm.$emit('blur')},"input":function($event){return _vm.$emit('input', $event.target.value)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }