import Vue from 'vue'
import VueTour from 'vue-tour'
import alfabeto from '@sas-te/alfabeto-vue'
import mainMenuVue from '@sas-te/main-menu-vue'

import 'swiper/css/swiper.css'

import App from './App'

import router from './router'
import store from './store'

import '@sas-te/alfabeto-vue/dist/alfabeto.css'
import 'vue-tour/dist/vue-tour.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import './plugins'
import '@/sass/overrides/_select.sass'

if (!window.dataLayer) {
  window.dataLayer = []
}

Vue.config.productionTip = false

Vue.use(VueTour)
Vue.use(alfabeto)
Vue.use(mainMenuVue, { store, env: process.env.VUE_APP_MODE })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
