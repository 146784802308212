import Vue from 'vue'
import Toasted from 'vue-toasted'
import feather from 'feather-icons'

const featherIcon = (type) => feather.icons[type].toSvg()

const toastOptions = {
  theme: 'primary',
  position: 'bottom-left',
  duration: 5000,
  keepOnHover: true,
}

Vue.use(Toasted, toastOptions)

const getIcon = (customIcon, defaultIcon) => (customIcon
  ? featherIcon(customIcon)
  : featherIcon(defaultIcon))

Vue.toasted.register(
  'error',
  ({ customIcon, message, title }) => {
    const icon = getIcon(customIcon, 'alert-triangle')

    if (message) {
      return `
      ${icon}
      <div>
        <div>${title || ''}</div>
        <p>${message}</p>
      </div>
    `
    }

    return `${icon} <p>${title}</p>`
  },
  { type: 'error' },
)

Vue.toasted.register(
  'success',
  ({ customIcon, message }) => {
    const icon = getIcon(customIcon, 'check')

    return `${icon} <p>${message}</p>`
  },
  { type: 'success' },
)

Vue.toasted.register(
  'info',
  ({ customIcon, message }) => {
    const icon = customIcon ? featherIcon(customIcon) : featherIcon('info')

    return `${icon} <p>${message}</p>`
  },
  { type: 'info' },
)

Vue.toasted.register(
  'warn',
  ({ customIcon, message }) => {
    const icon = customIcon ? featherIcon(customIcon) : featherIcon('alert-circle')

    return `${icon} <p>${message}</p>`
  },
  { type: 'warning' },
)
