import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
    video: {
        embedHtml: ''
    },
    channelsList: {
        loading: false,
        data: [],
        error: false,
        hasClassroomChannel: false,
    },
    grades: [],
    loading: false,
    selectedCategories: []
}

export default {
    state,
    getters,
    mutations,
    actions
}
