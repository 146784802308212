import videoApi from '@/api/video'
import * as types from '../mutation-types'
import store from '@/store'

export default {
    getVideoById: ({ commit }, videoId) => {
        commit(types.SET_LOADING, true)
        return videoApi
            .getVideo(videoId)
            .then(response => {
                commit(types.SET_VIDEO, response.data)
                commit(types.SET_LOADING, false)
                return response
            })
            .catch(() => {
                commit(types.SET_LOADING, false)
            })
    },

    destroyedGetVideoById: ({ commit }) => {
        commit(types.SET_VIDEO, { embedHtml: '' })
    },

    getChannelsList: ({ commit }) => {
        commit(types.SET_CHANNELS_LIST, { loading: true })
        return videoApi
            .getChannelsList()
            .then(response => {
                const channels = response.data.filter(c => c.id != process.env.VUE_APP_START_CHANNEL)
                commit(types.SET_CHANNELS_LIST, {
                    data: channels,
                    error: false,
                    hasClassroomChannel: response.data
                        .map(c => parseInt(c.id, 10))
                        .includes(parseInt(process.env.VUE_APP_START_CHANNEL, 10)),
                })
                commit(types.SET_CHANNELS_LIST, { loading: false })
            })
            .catch(e => {
                commit(types.SET_CHANNELS_LIST, { error: true })
                commit(types.SET_CHANNELS_LIST, { loading: false })
            })
    },

    getCategoriesByChannel: ({ commit }, channelId) => {
        return videoApi
            .getCategoriesByChannel(channelId)
            .then(response => {
                const categories = response.data
                commit(types.SET_CATEGORIES, categories)
                return categories
            }).catch(e => {
                commit(types.SET_CATEGORIES, [])
            })
    },

    getCategoryByPath: ({ commit }, path) => {
        return videoApi
            .getCategoryByPath(path)
            .then(response => {
                return response.data
            }).catch(e => {
            })
    },

    getVideosByChannel: ({ commit }, channel) => {
        return videoApi
            .getVideosByChannel(channel)
            .then(response => {
                const videos = response.data
                commit(types.SET_VIDEOS, videos)
                return videos
            }).catch(e => {
                commit(types.SET_VIDEOS, [])
            })
    },

    addSelectedCategory: ({ commit }, category) => {
        commit(types.ADD_SELECTED_CATEGORY, category)
    },

    removeSelectedCategory: ({ commit }) => {
        commit(types.REMOVE_SELECTED_CATEGORY)
    },

    clearSelectedCategories: ({ commit }) => {
        commit(types.CLEAR_SELECTED_CATEGORIES)
    }
}
