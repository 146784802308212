//loader store
export const SHOW_MAIN_LOADING = 'SHOW_MAIN_LOADING'
export const HIDE_MAIN_LOADING = 'HIDE_MAIN_LOADING'

//account store
export const SET_USER = 'SET_USER'
export const SET_TOKEN = 'SET_TOKEN'
export const CLEAR_USER = 'CLEAR_USER'

//video store
export const SET_VIDEO = 'SET_VIDEO'
export const SET_VIDEOS = 'SET_VIDEOS'
export const SET_CHANNELS_LIST = 'SET_CHANNELS_LIST'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_LOADING = 'SET_LOADING'
export const ADD_SELECTED_CATEGORY = 'ADD_SELECTED_CATEGORY'
export const REMOVE_SELECTED_CATEGORY = 'REMOVE_SELECTED_CATEGORY'
export const CLEAR_SELECTED_CATEGORIES = 'CLEAR_SELECTED_CATEGORIES'

//onboarding
export const SET_ONBOARDING = 'SET_ONBOARDING'
