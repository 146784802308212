const production = {
  AUTH_API: 'https://auth-server.portalsaseducacao.com.br',
  PORTAL_URL: 'https://portalsaseducacao.com.br',
  SAS_TV_HOST: 'https://video-service.sasdigital.com.br'
}

const staging = {
  AUTH_API: 'https://auth-server.staging.portalsaseducacao.com.br',
  PORTAL_URL: 'https://portal.staging.portalsaseducacao.com.br',
  SAS_TV_HOST: 'https://video-service.staging.sasdigital.com.br'
}

const development = {
  ...staging,
  PORTAL_URL: 'http://localhost:8080',
}

const urlsByMode = {
  production,
  staging,
  development,
}

const urls = urlsByMode[process.env.VUE_APP_MODE]

export default urls
